import {
  IAdminReportsData,
  PermissionsOfAdmin,
  TimeFormat,
  TypeOfUser,
  WorkshiftStatus,
  convertToDecimals,
  currency,
} from '@pharmaplan/common';
import strings from '../../../localization';
import { IUserProfileLinkSignature } from '../../../hooks/Admin/useUserProfileLink';
import {
  DynamicTableCell,
  OtherScreens,
  ReplacementTypes,
} from '../../../helpers/Constants';
import requestCancellationIcon from '../../../assets/svg/requestCancellation.svg';
import requestCancellationDisabledIcon from '../../../assets/svg/requestCancellationDisabled.svg';
import deleteRequestIcon from '../../../assets/svg/delete.svg';
import deleteRequestDisabledIcon from '../../../assets/svg/disableDelete.svg';
import editIcon from '../../../assets/svg/edit.svg';
import editDisabledIcon from '../../../assets/svg/editDisabled.svg';
import findMatchingIcon from '../../../assets/svg/findMatching.svg';
import findMatchingDisabledIcon from '../../../assets/svg/disableFindMatching.svg';
import approveBookingIcon from '../../../assets/svg/approveBooking.svg';
import approveBookingDisabledIcon from '../../../assets/svg/disabledApprove.svg';
import expressBookingIcon from '../../../assets/svg/expressBooking.svg';
import expressBookingDisabledIcon from '../../../assets/svg/disableExpressBooking.svg';
import { getDayMonthDateYear, hasAction } from '../../Reports/helpers';
import { getHoursDiff } from '../../Modals/WorkshiftDetails/utils';
import { timeString } from '../../../helpers/Functions';
import { statusMap } from '../PharmacyList/ListOfWorkshifts/helper';

import styles from './style';

const { Delete, ExpressBooking } = PermissionsOfAdmin;

interface IBookingReportsGeneric {
  data: IAdminReportsData[];
  goToUserProfile: IUserProfileLinkSignature;
  timeFormat: TimeFormat;
  can?: (param: PermissionsOfAdmin) => boolean;
}

interface IBookingReportsRow extends IBookingReportsGeneric {
  getImage: (logoId: string) => string;
  showExpressBooking: (bookingId: string, workshift?: boolean) => void;
  showRequestCancellation: (bookingId: string, dateTime: string) => void;
}

interface IAvailabilityReportsRow extends IBookingReportsGeneric {
  findMatchingWorkshifts: (
    availabilityId: string,
    code: string,
    pharmacistId: string
  ) => void;
  showDeletePreSubmit: (availabilityId: string, dateTime: string) => void;
}

interface IPostedWorkshiftsReportRow extends IBookingReportsGeneric {
  getImage: (logoId: string) => string;
  showExpressBooking: (bookingId: string, workshift?: boolean) => void;
  findMatchingAvailabilities: (workshiftId: string) => void;
  showWorkshiftDelete: (workshiftId: string, dateTime: string) => void;
  showEditWorkshift: (workshiftId: string) => void;
  selectedIds: Array<string>;
  handlePress: (id: string) => void;
}

interface IRequestedReportsRow extends IBookingReportsGeneric {
  getImage: (logoId: string) => string;
  goToApproveBooking: (
    bookingId: string,
    workshiftId: string,
    fromScreen: OtherScreens,
    eventIds?: Array<string> | null
  ) => () => void;
}

const { text, customLink, icon: img, bar, chip, checkbox } = DynamicTableCell;

enum Actions {
  requestCancellation = 'requestCancellation',
  expressBooking = 'expressBooking',
  editBooking = 'editBooking',
  findMatching = 'findMatching',
  matchingAvailabilities = 'matchingAvailabilities',
  approve = 'approve',
  delete = 'delete',
  editWorkshift = 'editWorkshift',
}

const statusStyleMap = {
  [WorkshiftStatus.Posted]: styles.posted,
  [WorkshiftStatus.Booked]: styles.booked,
  [WorkshiftStatus.Requested]: styles.booked,
  [WorkshiftStatus.Unfulfilled]: styles.free,
};

export const dateAndTime = (
  selectedDate: string,
  sDate: string,
  eDate: string,
  timeFormat: TimeFormat,
) => {
  const formattedDate = getDayMonthDateYear(selectedDate);
  const time = timeString(sDate, eDate, timeFormat);
  const dateTime = `${formattedDate} | ${time}`;

  return dateTime;
};

const ActionTooltips = {
  [Actions.requestCancellation]: {
    enabled: strings.requestCancellation,
    disabled: strings.outOfDate,
  },
  [Actions.editBooking]: { enabled: strings.edit, disabled: strings.outOfDate },
  [Actions.delete]: { enabled: strings.delete, disabled: strings.outOfDate },
  [Actions.expressBooking]: {
    enabled: strings.expressBooking,
    disabled: strings.outOfDate,
  },
  [Actions.approve]: { enabled: strings.approve, disabled: strings.outOfDate },
  [Actions.findMatching]: {
    enabled: strings.findMatchingWorkshifts,
    disabled: strings.outOfDate,
  },
  [Actions.matchingAvailabilities]: {
    enabled: strings.matchingAvailabilities,
    disabled: strings.outOfDate,
  },
  [Actions.editWorkshift]: {
    enabled: strings.editWorkshift,
    disabled: strings.outOfDate,
  },
};

const ActionIcons = {
  [Actions.requestCancellation]: {
    enabled: requestCancellationIcon,
    disabled: requestCancellationDisabledIcon,
  },
  [Actions.editBooking]: { enabled: editIcon, disabled: editDisabledIcon },
  [Actions.delete]: {
    enabled: deleteRequestIcon,
    disabled: deleteRequestDisabledIcon,
  },
  [Actions.expressBooking]: {
    enabled: expressBookingIcon,
    disabled: expressBookingDisabledIcon,
  },
  [Actions.approve]: {
    enabled: approveBookingIcon,
    disabled: approveBookingDisabledIcon,
  },
  [Actions.findMatching]: {
    enabled: findMatchingIcon,
    disabled: findMatchingDisabledIcon,
  },
  [Actions.matchingAvailabilities]: {
    enabled: findMatchingIcon,
    disabled: findMatchingDisabledIcon,
  },
  [Actions.editWorkshift]: {
    enabled: editIcon,
    disabled: editDisabledIcon,
  },
};

const handleIcon = (action: Actions, date: string, isBooked = false) => {
  const isDisabled = !hasAction(date);

  const icon = isDisabled || isBooked
    ? ActionIcons[action].disabled
    : ActionIcons[action].enabled;
  const tooltip = isDisabled
    ? ActionTooltips[action].disabled
    : ActionTooltips[action].enabled;

  return { icon, tooltip };
};

export const bookingReportsHeader = () =>
  [
    {
      key: '0',
      label: strings.banner,
    },
    {
      key: '1',
      label: strings.pharmacy,
      sortingKey: 'pharmacyName',
    },
    {
      key: '2',
      label: strings.wsCode,
      sortingKey: 'code',
    },
    {
      key: '3',
      label: `${strings.date} | ${strings.time}`,
      sortingKey: 'selectedDate',
    },
    {
      key: '4',
      label: strings.hours,
      isCenterAligned: true,
      sortingKey: 'hours',
    },
    {
      key: '5',
      label: strings.replacement,
      sortingKey: 'pharmacistName',
    },
    {
      key: '6',
      label: strings.type,
      sortingKey: 'replacementType',
    },
    {
      key: '7',
      label: strings.pharmacistHourlyRate,
      sortingKey: 'pharmacistHourlyRate',
    },
    {
      key: '8',
      label: strings.pharmacyHourlyRate,
      sortingKey: 'pharmacyHourlyRate',
    },
    {
      key: '9',
      label: strings.action,
    },
  ];

export const requestedReportsHeader = () =>
  [
    {
      key: '0',
      label: strings.banner,
    },
    {
      key: '1',
      label: strings.pharmacy,
      sortingKey: 'pharmacyName',
    },
    {
      key: '2',
      label: strings.wsCode,
      sortingKey: 'code',
    },
    {
      key: '3',
      label: `${strings.date} | ${strings.time}`,
      sortingKey: 'selectedDate',
    },
    {
      key: '4',
      label: strings.hours,
      isCenterAligned: true,
      sortingKey: 'hours',
    },
    {
      key: '5',
      label: strings.replacement,
      sortingKey: 'pharmacistName',
    },
    {
      key: '6',
      label: strings.type,
      sortingKey: 'replacementType',
    },
    {
      key: '7',
      label: strings.pharmacistHourlyRate,
      sortingKey: 'pharmacistHourlyRate',
    },
    {
      key: '8',
      label: strings.pharmacyHourlyRate,
      sortingKey: 'pharmacyHourlyRate',
    },
    {
      key: '9',
      label: strings.action,
    },
  ];

export const availabilityReportsHeader = () =>
  [
    {
      key: '0',
      label: strings.code,
      sortingKey: 'code',
    },
    {
      key: '1',
      label: `${strings.date} | ${strings.time}`,
      sortingKey: 'pharmacistName',
    },
    {
      key: '2',
      label: strings.hours,
      isCenterAligned: true,
      sortingKey: 'hours',
    },
    {
      key: '3',
      label: strings.pharmacist,
      sortingKey: 'pharmacistName',
    },
    {
      key: '4',
      label: strings.status,
      sortingKey: 'availabilityStatus',
    },
    {
      key: '5',
      label: strings.action,
    },
  ];

export const postedWorkshiftsHeader = () =>
  [
    {
      key: '0',
      label: strings.banner,
    },
    {
      key: '1',
      label: strings.pharmacy,
      sortingKey: 'pharmacyName',
    },
    {
      key: '2',
      label: strings.wsCode,
      sortingKey: 'code',
    },
    {
      key: '3',
      label: `${strings.date} | ${strings.time}`,
      sortingKey: 'selectedDate',
    },
    {
      key: '4',
      label: strings.hours,
      isCenterAligned: true,
      sortingKey: 'hours',
    },
    {
      key: '5',
      label: strings.replacementType,
      sortingKey: 'replacementType',
    },
    {
      key: '6',
      label: strings.status,
      sortingKey: 'status',
    },
    {
      key: '7',
      label: strings.pharmacyHourlyRate,
      sortingKey: 'pharmacyHourlyRate',
    },
    {
      key: '8',
      label: strings.pharmacistHourlyRate,
      sortingKey: 'pharmacistHourlyRate',
    },
    {
      key: '9',
      label: strings.action,
    },
    {
      key: '10',
      label: strings.deleteRecords,
    },
  ];

export const bookingReportsRow = ({
  data,
  getImage,
  goToUserProfile,
  timeFormat,
  can,
  showExpressBooking,
  showRequestCancellation,
}: IBookingReportsRow) =>
  data.map((item) => {
    const {
      code,
      bookingId,
      logoId,
      pharmacyHourlyRate,
      replacementType,
      pharmacyName,
      pharmacistName,
      startDate,
      endDate,
      selectedDate,
      pharmacyId,
      pharmacistId,
      pharmacistHourlyRate,
    } = item ?? {};

    const disabled = !hasAction(startDate);
    const date = getDayMonthDateYear(startDate);
    const time = timeString(startDate, endDate, timeFormat);

    const dateTime = `${date} | ${time}`;
    const canViewExpressBooking = can?.(ExpressBooking);

    return {
      key: bookingId,
      data: [
        {
          key: `0-${bookingId}`,
          value: getImage(logoId),
          type: img,
        },
        {
          key: `1-${bookingId}`,
          value: {
            label: pharmacyName,
            handlePress: goToUserProfile({
              personnelId: pharmacyId,
              userType: TypeOfUser.pharmacy,
              fromScreen: OtherScreens.AdminReports,
            }),
          },
          type: customLink,
          maxWidth: 150,
        },
        {
          key: `3-${bookingId}`,
          value: code,
          type: text,
        },
        {
          key: `4-${bookingId}`,
          value: dateAndTime(selectedDate, startDate, endDate, timeFormat),
          type: text,
          maxWidth: 200,
        },
        {
          key: `5-${bookingId}`,
          value: getHoursDiff(startDate, endDate),
          type: text,
          isCenterAligned: true,
        },
        {
          key: `6-${bookingId}`,
          value: {
            label: pharmacistName,
            handlePress: goToUserProfile({
              personnelId: pharmacistId,
              userType: TypeOfUser.pharmacist,
              fromScreen: OtherScreens.AdminReports,
            }),
          },
          type: customLink,
        },
        {
          key: `7-${bookingId}`,
          value: ReplacementTypes()[replacementType],
          type: text,
        },
        {
          key: `9-${bookingId}`,
          value: `$${convertToDecimals(pharmacistHourlyRate)}`,
          type: text,
          isCenterAligned: true,
        },
        {
          key: `10-${bookingId}`,
          value: `$${convertToDecimals(pharmacyHourlyRate)}`,
          type: text,
          isCenterAligned: true,
        },
        {
          key: `11-${bookingId}`,
          value: [
            {
              key: '1',
              icon: handleIcon(Actions.requestCancellation, startDate).icon,
              tooltip: handleIcon(Actions.requestCancellation, startDate)
                .tooltip,
              disabled,
              onClick: () =>
                showRequestCancellation(bookingId, dateTime),
            },
            canViewExpressBooking && {
              key: '2',
              icon: handleIcon(Actions.editBooking, startDate).icon,
              tooltip: handleIcon(Actions.editBooking, startDate).tooltip,
              disabled,
              onClick: () => {
                showExpressBooking(bookingId);
              },
            },
          ],
          type: bar,
        },
      ],
    };
  });

export const availabilitesReportsRow = ({
  data,
  goToUserProfile,
  timeFormat,
  findMatchingWorkshifts,
  showDeletePreSubmit,
  can,
}: IAvailabilityReportsRow) =>
  data.map((item) => {
    const {
      code,
      availableTimeId,
      pharmacistName,
      startDate,
      availabilityStatus,
      endDate,
      selectedDate,
      pharmacistId,
    } = item ?? {};

    const statusStyle = availabilityStatus ? styles.booked : styles.free;
    const status = availabilityStatus ? strings.booked : strings.free;
    const disabled = !hasAction(startDate) || availabilityStatus;
    const canDelete = can?.(Delete);

    const dateTime = dateAndTime(selectedDate, startDate, endDate, timeFormat);

    return {
      key: availableTimeId,
      data: [
        {
          key: `0-${availableTimeId}`,
          value: code,
          type: text,
        },
        {
          key: `1-${availableTimeId}`,
          value: dateTime,
          type: text,
        },
        {
          key: `2-${availableTimeId}`,
          value: getHoursDiff(startDate, endDate),
          type: text,
          isCenterAligned: true,
        },
        {
          key: `3-${availableTimeId}`,
          value: {
            label: pharmacistName,
            handlePress: goToUserProfile({
              personnelId: pharmacistId,
              userType: TypeOfUser.pharmacist,
            }),
          },
          type: customLink,
        },
        {
          key: `4-${availableTimeId}`,
          value: {
            label: status,
            style: statusStyle,
          },
          type: chip,
        },
        {
          key: `5-${availableTimeId}`,
          value: [
            {
              key: '1',
              icon: handleIcon(
                Actions.findMatching,
                startDate,
                availabilityStatus,
              ).icon,
              tooltip: handleIcon(
                Actions.findMatching,
                startDate,
                availabilityStatus,
              ).tooltip,
              disabled,
              onClick: () =>
                findMatchingWorkshifts(availableTimeId, code, pharmacistId),
            },
            canDelete && {
              key: '2',
              icon: handleIcon(Actions.delete, startDate, availabilityStatus)
                .icon,
              tooltip: handleIcon(Actions.delete, startDate, availabilityStatus)
                .tooltip,
              disabled,
              onClick: () =>
                showDeletePreSubmit(availableTimeId, dateTime),
            },
          ],
          type: bar,
        },
      ],
    };
  });

export const requestedReportsRow = ({
  data,
  getImage,
  goToUserProfile,
  timeFormat,
  goToApproveBooking,
}: IRequestedReportsRow) =>
  data.map((item) => {
    const {
      code,
      bookingId,
      logoId,
      pharmacyHourlyRate,
      pharmacistHourlyRate,
      replacementType,
      pharmacyName,
      pharmacistName,
      startDate,
      endDate,
      selectedDate,
      workshiftId,
      pharmacistId,
      pharmacyId,
    } = item ?? {};

    const disabled = !hasAction(startDate);

    return {
      key: bookingId,
      data: [
        {
          key: `0-${bookingId}`,
          value: getImage(logoId),
          type: img,
        },
        {
          key: `1-${bookingId}`,
          value: {
            label: pharmacyName,
            handlePress: goToUserProfile({
              personnelId: pharmacyId,
              userType: TypeOfUser.pharmacy,
              fromScreen: OtherScreens.AdminReports,
            }),
          },
          maxWidth: 150,
          type: customLink,
        },
        {
          key: `3-${bookingId}`,
          value: code,
          type: text,
        },
        {
          key: `4-${bookingId}`,
          value: dateAndTime(selectedDate, startDate, endDate, timeFormat),
          type: text,
          maxWidth: 200,
        },
        {
          key: `5-${bookingId}`,
          value: getHoursDiff(startDate, endDate),
          type: text,
          isCenterAligned: true,
        },
        {
          key: `6-${bookingId}`,
          value: {
            label: pharmacistName,
            handlePress: goToUserProfile({
              personnelId: pharmacistId,
              userType: TypeOfUser.pharmacist,
              fromScreen: OtherScreens.AdminReports,
            }),
          },
          maxWidth: 150,
          type: customLink,
        },
        {
          key: `7-${bookingId}`,
          value: ReplacementTypes()[replacementType],
          type: text,
        },
        {
          key: `9-${bookingId}`,
          value: `${currency}${convertToDecimals(pharmacyHourlyRate)}`,
          type: text,
          isCenterAligned: true,
        },
        {
          key: `10-${bookingId}`,
          value: `${currency}${convertToDecimals(pharmacistHourlyRate)}`,
          type: text,
          isCenterAligned: true,
        },
        {
          key: `11-${bookingId}`,
          value: [
            {
              key: '1',
              icon: handleIcon(Actions.approve, startDate).icon,
              tooltip: handleIcon(Actions.approve, startDate).tooltip,
              disabled,
              onClick: goToApproveBooking(
                bookingId,
                workshiftId,
                OtherScreens.AdminRequestedReports,
              ),
            },
          ],
          type: bar,
        },
      ],
    };
  });

export const postedWorkshiftsReportRow = ({
  data,
  getImage,
  goToUserProfile,
  timeFormat,
  findMatchingAvailabilities,
  can,
  showExpressBooking,
  showWorkshiftDelete,
  showEditWorkshift,
  selectedIds,
  handlePress,
}: IPostedWorkshiftsReportRow) =>
  data.map((item) => {
    const {
      code,
      workshiftId,
      logoId,
      pharmacyHourlyRate,
      pharmacistHourlyRate,
      replacementType,
      pharmacyName,
      startDate,
      endDate,
      selectedDate,
      status,
      pharmacyId,
    } = item ?? {};

    const disabled = !hasAction(startDate);
    const canDelete = can?.(Delete);
    const canViewExpressBooking = can?.(ExpressBooking);

    return {
      key: workshiftId,
      data: [
        {
          key: `0-${workshiftId}`,
          value: getImage(logoId),
          type: img,
        },
        {
          key: `1-${workshiftId}`,
          value: {
            label: pharmacyName,
            handlePress: goToUserProfile({
              personnelId: pharmacyId,
              userType: TypeOfUser.pharmacy,
              fromScreen: OtherScreens.AdminReports,
            }),
          },
          maxWidth: 150,
          type: customLink,
        },
        {
          key: `3-${workshiftId}`,
          value: code,
          type: text,
        },
        {
          key: `4-${workshiftId}`,
          value: dateAndTime(selectedDate, startDate, endDate, timeFormat),
          type: text,
          maxWidth: 200,
        },
        {
          key: `5-${workshiftId}`,
          value: getHoursDiff(startDate, endDate),
          type: text,
          isCenterAligned: true,
        },
        {
          key: `6-${workshiftId}`,
          value: ReplacementTypes()[replacementType],
          type: text,
        },
        {
          key: `7-${workshiftId}`,
          value: {
            label: statusMap[status],
            style: statusStyleMap[status],
          },
          type: chip,
        },
        {
          key: `8-${workshiftId}`,
          value: `${currency}${convertToDecimals(pharmacyHourlyRate)}`,
          type: text,
          isCenterAligned: true,
        },
        {
          key: `9-${workshiftId}`,
          value: `${currency}${convertToDecimals(pharmacistHourlyRate)}`,
          type: text,
          isCenterAligned: true,
        },
        {
          key: `11-${workshiftId}`,
          value: [
            {
              key: '1',
              icon: handleIcon(Actions.matchingAvailabilities, startDate).icon,
              tooltip: handleIcon(Actions.matchingAvailabilities, startDate)
                .tooltip,
              disabled,
              onClick: () => {
                findMatchingAvailabilities(workshiftId);
              },
            },
            {
              key: '4',
              icon: handleIcon(Actions.editWorkshift, startDate).icon,
              tooltip: handleIcon(Actions.editWorkshift, startDate).tooltip,
              disabled,
              onClick: () => {
                showEditWorkshift(workshiftId);
              },
            },
            canDelete && {
              key: '2',
              icon: handleIcon(Actions.delete, startDate).icon,
              tooltip: handleIcon(Actions.delete, startDate).tooltip,
              disabled,
              onClick: () => {
                showWorkshiftDelete(
                  workshiftId,
                  dateAndTime(selectedDate, startDate, endDate, timeFormat),
                );
              },
            },
            canViewExpressBooking && {
              key: '3',
              icon: expressBookingIcon,
              tooltip: strings.expressBooking,
              disabled,
              onClick: () =>
                showExpressBooking(workshiftId, true),
            },
          ],
          type: bar,
        },
        canDelete && {
          key: '8',
          value: {
            checked: !!selectedIds?.includes(workshiftId),
            handlePress: handlePress(workshiftId),
            disabled,
            tooltipText: strings.outOfDate,
          },
          type: checkbox,
        },
      ],
    };
  });
