import { useEffect, useState } from 'react';
import { IActionTypeFormik, getLogsFilters, successSelector } from '@pharmaplan/common';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../useAppDispatch';
import useActivityLogsFormik from './useLogsFormik';
import { IDynamicTableObject } from '../../../components/DynamicTable/types';
import useLogsHandler from './useLogsHandler';
import { MomentTimeFormats } from '../../../helpers/Constants';
import { useAppSelector } from '../../useAppSelector';
import useTableSort from '../../useTableSort';

const useLogs = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);

  const { isDesc, orderBy, handleSorting, order, resetSorting } = useTableSort();
  const { formik } = useActivityLogsFormik(setPage, orderBy, isDesc);
  const { headers, rows, title, api, data, totalCount, success } = useLogsHandler();

  const { resetForm, values } = formik;
  const {
    startDate,
    endDate,
    performerText,
    userText,
    action,
    pharmacistText,
  } = values ?? {};

  const { module, key } = action as unknown as IActionTypeFormik ?? {};

  const loadSuccess = useAppSelector((state) =>
    successSelector([success], state));

  const callWithFilters = (pagination?: number, sortBy?: string, desc?: boolean) => {
    dispatch(
      api({
        startDate: startDate
          ? (startDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        endDate: endDate
          ? (endDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        page: pagination ?? 1,
        action: key ?? action,
        performerText,
        userText,
        module,
        pharmacistText,
        sortBy,
        desc,
      }),
    );
  };

  const initCall = () => {
    dispatch(
      api({
        startDate: null,
        endDate: null,
        action: null,
        module: null,
        page: 1,
        performerText: '',
        userText: '',
        pharmacistText: '',
      }),
    );
  };

  const handlePagination = (_: unknown, selectedPage: number) => {
    const gotoPage = selectedPage + 1;
    setPage(gotoPage);
    callWithFilters(gotoPage, orderBy, isDesc);
  };

  const table: IDynamicTableObject = {
    headerBar: [],
    headers,
    rows,
  };

  const handleReset = () => {
    setPage(1);
    resetForm();
    initCall();
  };

  useEffect(() => {
    dispatch(getLogsFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleReset();
    resetSorting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (orderBy) {
      setPage(1);
      callWithFilters(1, orderBy, isDesc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order]);

  return {
    formik,
    page,
    table,
    totalCount,
    title,
    data,
    loadSuccess,
    handleReset,
    handlePagination,
    order,
    orderBy,
    handleSorting,
  };
};

export default useLogs;
