import {
  ConfirmationTypes,
  HelpTypes,
  IAdminPendingActivationsData,
  PermissionsOfAdmin,
  PermissionsRemovalKeys,
  TimeFormat,
} from '@pharmaplan/common';
import { DynamicTableCell } from '../../../helpers/Constants';
import strings from '../../../localization';
import activateUserIcon from '../../../assets/svg/activateUserIcon.svg';
import deleteRequestIcon from '../../../assets/svg/delete.svg';
import { getDayMonthDateYear, getHourMinute } from '../../Reports/helpers';
import { removeBasedOnPermissions } from '../../../helpers/permissionsHelper';

const { icon } = DynamicTableCell;
const { PermanentDelete } = PermissionsRemovalKeys;
const { PermanentDelete: PermanentDeletePermission } = PermissionsOfAdmin;

export const deleteHeaders = (can: (params: PermissionsOfAdmin) => boolean) => {
  const arr = [
    {
      key: '0',
      label: strings.name,
      sortingKey: 'userName',
    },
    {
      key: '1',
      label: strings.contactEmail,
      sortingKey: 'email',
    },
    {
      key: '2',
      label: strings.formatString(
        strings.createdEvent,
        `${strings.date} | ${strings.time}`,
      ) as string,
      isCenterAligned: true,
      sortingKey: 'createdDate',
    },
    {
      key: '4',
      isCenterAligned: true,
      label: strings.formatString(
        strings.deleteEvent,
        `${strings.date} | ${strings.time}`,
      ) as string,
      sortingKey: 'deletedDate',
    },
    {
      key: '6',
      label: strings.userType,
      sortingKey: 'userType',
    },
    {
      key: PermanentDelete,
      label: strings.deleteRecords,
    },
  ];

  return removeBasedOnPermissions(arr, [PermanentDeletePermission], can);
};

export const pharmacyHeader = (canActivate: boolean) => {
  const arr = [
    {
      key: 'a',
      label: strings.banner,
    },
    {
      key: '0',
      label: strings.name,
      sortingKey: 'userName',
    },
    {
      key: '1',
      label: strings.contactName,
      sortingKey: 'contactName',
    },
    {
      key: '2',
      isCenterAligned: true,
      label: strings.formatString(
        strings.createdEvent,
        `${strings.date} | ${strings.time}`,
      ) as string,
      sortingKey: 'createdDate',
    },
    {
      key: '3',
      label: strings.owner,
      sortingKey: 'ownerName',
    },
    {
      key: '4',
      label: strings.action,
    },
  ];

  return canActivate ? arr : arr.slice(0, -1);
};

export const pharmacistHeader = (canActivate: boolean) => {
  const arr = [
    {
      key: '0',
      label: strings.name,
      sortingKey: 'userName',
    },
    {
      key: '1',
      label: strings.email,
      sortingKey: 'email',
    },
    {
      key: '2',
      isCenterAligned: true,
      label: strings.formatString(
        strings.createdEvent,
        `${strings.date} | ${strings.time}`,
      ) as string,
      sortingKey: 'createdDate',
    },
    {
      key: '3',
      label: strings.owner,
      sortingKey: 'ownerName',
    },
    {
      key: '4',
      label: strings.action,
    },
  ];

  return canActivate ? arr : arr.slice(0, -1);
};

export const pharmacistRowConfig = (
  data: IAdminPendingActivationsData[],
  timeFormat: TimeFormat,
  handleUserNameClick: (
    id: string,
    screenNumber: number,
    hasOwner: boolean,
    enableActions: boolean
  ) => void,
  confirmAction: (confirmationType: ConfirmationTypes, id: string) => void,
  canActivate: boolean,
) =>
  data?.map((item) => {
    const {
      userName,
      createdDate,
      ownerName,
      userId,
      enableActions,
      email,
      personnelId,
    } = item ?? {};
    const date = getDayMonthDateYear(createdDate);
    const time = getHourMinute(createdDate, timeFormat);

    const enableActionsTooltip = (tooltip: string) =>
      (enableActions ? tooltip : strings.ownedByAnotherAdmin);

    return {
      key: userId,
      data: [
        {
          key: `0-${userId}`,
          value: {
            label: userName,
            handlePress: () =>
              handleUserNameClick(personnelId, 2, !!ownerName, enableActions),
          },
          maxWidth: 180,
          type: DynamicTableCell.customLink,
        },
        {
          key: `1-${userId}`,
          value: email,
          maxWidth: 180,
          type: DynamicTableCell.text,
        },
        {
          key: `2-${userId}`,
          value: `${date} | ${time} `,
          isCenterAligned: true,
          type: DynamicTableCell.text,
        },
        {
          key: `4-${userId}`,
          value: ownerName,
          type: DynamicTableCell.text,
        },
        canActivate
          ? {
            key: `5-${userId}`,
            value: [
              {
                key: '1',
                disabled: !enableActions,
                icon: activateUserIcon,
                tooltip: enableActionsTooltip(strings.activate),
                onClick: () =>
                  confirmAction(ConfirmationTypes.activateUser, personnelId),
              },
              {
                key: '2',
                tooltip: enableActionsTooltip(strings.delete),
                disabled: !enableActions,
                icon: deleteRequestIcon,
                onClick: () =>
                  confirmAction(ConfirmationTypes.deleteEntry, personnelId),
              },
            ],
            type: DynamicTableCell.bar,
          }
          : {},
      ],
    };
  });

export const pharmacyRowConfig = (
  data: IAdminPendingActivationsData[],
  timeFormat: TimeFormat,
  handleUserNameClick: (
    id: string,
    screenNumber: number,
    hasOwner: boolean,
    enableActions: boolean
  ) => void,
  confirmAction: (confirmationType: ConfirmationTypes, id: string) => void,
  canActivate: boolean,
  getImage: (id: string) => string,
) =>
  data?.map((item) => {
    const {
      userName,
      createdDate,
      userId,
      enableActions,
      contactName,
      personnelId,
      ownerName,
      logoId,
    } = item ?? {};
    const date = getDayMonthDateYear(createdDate);
    const time = getHourMinute(createdDate, timeFormat);

    const image = getImage(logoId ?? '');

    const enableActionsTooltip = (tooltip: string) =>
      (enableActions ? tooltip : strings.ownedByAnotherAdmin);

    return {
      key: userId,
      data: [
        {
          key: `a-${userId}`,
          value: image,
          type: icon,
        },
        {
          key: `0-${userId}`,
          value: {
            label: userName,
            handlePress: () =>
              handleUserNameClick(personnelId, 1, !!ownerName, enableActions),
          },
          maxWidth: 180,
          type: DynamicTableCell.customLink,
        },
        {
          key: `1-${userId}`,
          value: contactName,
          maxWidth: 100,
          type: DynamicTableCell.text,
        },
        {
          key: `2-${userId}`,
          value: `${date} | ${time}`,
          isCenterAligned: true,
          type: DynamicTableCell.text,
        },
        {
          key: `3-${userId}`,
          value: ownerName,
          type: DynamicTableCell.text,
        },
        canActivate
          ? {
            key: `4-${userId}`,
            value: [
              {
                key: '1',
                icon: activateUserIcon,
                tooltip: enableActionsTooltip(strings.activate),
                disabled: !enableActions,
                onClick: () =>
                  confirmAction(ConfirmationTypes.activateUser, personnelId),
              },
              {
                key: '2',
                tooltip: enableActionsTooltip(strings.delete),
                disabled: !enableActions,
                icon: deleteRequestIcon,
                onClick: () =>
                  confirmAction(ConfirmationTypes.deleteEntry, personnelId),
              },
            ],
            type: DynamicTableCell.bar,
          }
          : {},
      ],
    };
  });

export const deletedUsersRowConfig = (
  data: IAdminPendingActivationsData[],
  timeFormat: TimeFormat,
  handleUserNameClick: (
    id: string,
    screenNumber: number,
    hasOwner: boolean,
    enableActions: boolean
  ) => void,
  handlePress: (userId: string) => () => void,
  selectedItems: Array<string>,
  canDelete: boolean,
) =>
  data?.map((item) => {
    const { userName, createdDate, userId, deletedDate, userType, email } = item ?? {};
    const createDate = getDayMonthDateYear(createdDate);
    const createTime = getHourMinute(createdDate, timeFormat);

    const deleteDate = getDayMonthDateYear(deletedDate ?? '');
    const deleteTime = getHourMinute(deletedDate ?? '', timeFormat);

    const isPharmacist = `${userType}` === HelpTypes.pharmacist;

    const userTypeString = isPharmacist ? strings.pharmacist : strings.pharmacy;
    const screenNumber = isPharmacist ? 2 : 1;
    return {
      key: userId,
      data: [
        {
          key: `0-${userId}`,
          value: {
            label: userName,
            handlePress: () =>
              handleUserNameClick(userId, screenNumber, false, false),
          },
          type: DynamicTableCell.customLink,
        },
        {
          key: `1-${userId}`,
          value: email,
          maxWidth: 180,
          type: DynamicTableCell.text,
        },
        {
          key: `2-${userId}`,
          value: `${createDate} | ${createTime} `,
          type: DynamicTableCell.text,
          isCenterAligned: true,
        },
        {
          key: `4-${userId}`,
          isCenterAligned: true,
          value: `${deleteDate} | ${deleteTime} `,
          type: DynamicTableCell.text,
        },
        {
          key: `6-${userId}`,
          value: userTypeString,
          type: DynamicTableCell.text,
        },
        canDelete && {
          key: `7-${userId}`,
          value: {
            checked: selectedItems.includes(userId),
            handlePress: handlePress(userId),
          },
          isCenterAligned: true,
          type: DynamicTableCell.checkbox,
        },
      ],
    };
  });
