import React, { useEffect } from 'react';

import { FormikValues, useFormik } from 'formik';
import { DateTime } from 'luxon';

import {
  adminActions,
  isLoadingSelector,
  successOrSelector,
} from '@pharmaplan/common';
import { MomentTimeFormats } from '../../../helpers/Constants';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import DynamicTable from '../../DynamicTable';

import usePendingCancellations from '../../../hooks/Admin/PendingCancellations/usePendingCancellations';
import { useAppSelector } from '../../../hooks/useAppSelector';

import PendingCancellationHeader from './PendingCancellationHeader';
import useTableSort from '../../../hooks/useTableSort';

const {
  getPendingCancellations,
  getCancelledBookings,
  getRejectedCancellations,
  rejectPendingCancellation,
  approvePendingCancellation,
} = adminActions;

const actions = [
  getPendingCancellations,
  getCancelledBookings,
  getRejectedCancellations,
];

const approveRejectActions = [
  approvePendingCancellation,
  rejectPendingCancellation,
];

const PendingCancellations = () => {
  const dispatch = useAppDispatch();

  const { isDesc, orderBy, handleSorting, order, resetSorting } = useTableSort();

  const loadSuccess = useAppSelector((state) =>
    isLoadingSelector(actions, state));
  const approveRejectSuccess = useAppSelector((state) =>
    successOrSelector(approveRejectActions, state));

  const {
    table,
    apiDataMap,
    page,
    setPage,
    selectAll,
    selectAllFunc,
    handleDelete,
    setSelectedTab,
    selectedTab,
    handleReset,
  } = usePendingCancellations();

  const { totalCount } = apiDataMap.list;
  const { api } = apiDataMap;

  const handleSubmit = (values: FormikValues) => {
    const { startDate, endDate, pharmacyText, pharmacistText, code } = values ?? {};
    handleReset();
    setPage(1);
    dispatch(
      api({
        page: 1,
        startDate: startDate
          ? (startDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        endDate: endDate
          ? (endDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        code,
        pharmacyText,
        pharmacistText,
        sortBy: orderBy,
        desc: isDesc,
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      startDate: null,
      endDate: null,
      code: '',
      pharmacyText: '',
      pharmacistText: '',
    },
    onSubmit: handleSubmit,
  });

  const { startDate, endDate, pharmacyText, pharmacistText, code } = formik.values ?? {};

  const handlePagination = (_: unknown, selectedPage: number) => {
    setPage(selectedPage + 1);
    dispatch(
      api({
        page: selectedPage + 1,
        startDate: startDate
          ? (startDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        endDate: endDate
          ? (endDate as DateTime).toFormat(MomentTimeFormats.fullDate)
          : null,
        pharmacistText,
        pharmacyText,
        code,
        sortBy: orderBy,
        desc: isDesc,
      }),
    );
  };

  const reset = () => {
    setPage(1);
    formik.resetForm();
    handleReset();
    resetSorting();
    dispatch(api({ page: 1 }));
  };

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  useEffect(() => {
    if (approveRejectSuccess) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveRejectSuccess]);

  useEffect(() => {
    if (orderBy) {
      setPage(1);
      dispatch(
        api({
          page: 1,
          sortBy: orderBy,
          desc: isDesc,
          ...formik.values,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order]);

  return (
    <DynamicTable
      table={table}
      loadSuccess={!loadSuccess}
      totalCount={totalCount}
      page={page}
      order={order}
      orderBy={orderBy}
      handleSort={handleSorting}
      handlePagination={handlePagination}
      customHeader={(
        <PendingCancellationHeader
          formik={formik}
          selectedTab={selectedTab}
          selectAll={selectAll}
          setSelected={setSelectedTab}
          handleSelectAll={selectAllFunc}
          handleDelete={handleDelete}
          handleReset={reset}
        />
      )}
      showHeader={false}
    />
  );
};

export default PendingCancellations;
