import React, { useEffect, useState } from 'react';

import { Box } from '@mui/system';
import { FormikValues, useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  adminActions,
  adminGetIncompatibleMatches,
  adminIncompatibleMatches,
  adminIncompatibleMatchesSearch,
  adminResetValue,
  HelpTypes,
  PermissionsOfAdmin,
  persistIncompatibleMatchesSearch,
  successSelector,
} from '@pharmaplan/common';

import useUserProfileLink from '../../../hooks/Admin/useUserProfileLink';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useAutoComplete from '../../../hooks/useAutocomplete';
import useAdminPermissions from '../../../hooks/Admin/useAdminPermissions';
import useTableSort from '../../../hooks/useTableSort';
import strings from '../../../localization';
import CreateNew from '../../common/CreateNew';
import DynamicTable from '../../DynamicTable';
import { IDynamicTableObject } from '../../DynamicTable/types';
import { Constants } from '../../../helpers/Constants';

import { canCreateNew } from '../PharmacistCapacities/helper';
import IncompatibleMatchListHeader from './IncompatibleMatchListHeader';
import EmptyIncompatibleMatches from './EmptyIncompatibleMatches';
import { incompatibleMatchHeaders, incompatibleMatchRows } from './helper';

const { pharmacy } = HelpTypes;
const successAction = adminActions.getIncompatibleMatchList;
const resetValues = ['incompatibleMatches', 'incompatibleMatchesSearch'];

const IncompatibleMatches = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const { pharmacyId: pID, pharmacyEmail } = state ?? {};

  const { can } = useAdminPermissions();
  const canViewUpdateList = can(PermissionsOfAdmin.SetupIncompatibleMatch);

  const { isDesc, orderBy, handleSorting, order } = useTableSort();

  const [page, setPage] = useState(1);
  const success = useAppSelector((successState) =>
    successSelector([successAction], successState));

  const incompatibleMatchesList = useAppSelector(adminIncompatibleMatches);
  const searchHistory = useAppSelector(adminIncompatibleMatchesSearch);

  const { pharmacies, pharmacyId, pharmacySelected } = searchHistory;

  const { goToUserProfile } = useUserProfileLink();
  const { debouncedInput } = useAutoComplete({
    userTypes: [pharmacy],
  });

  const isPharmacyList = pathname === Constants.paths.admin.pharmacyListIncompatible;

  const handleSubmit = (values: FormikValues) => {
    const {
      pharmacyId: formikPharmacyId,
      pharmacies: formikPharmacies,
      pharmacySelected: formikPharmacySelected,
    } = values ?? {};

    dispatch(
      persistIncompatibleMatchesSearch({
        pharmacies: formikPharmacies,
        pharmacySelected: formikPharmacySelected,
        pharmacyId: formikPharmacyId,
      }),
    );

    dispatch(
      adminGetIncompatibleMatches({
        pharmacyId: formikPharmacyId,
        paging: {
          page: 1,
          sortBy: orderBy,
          desc: isDesc,
        },
      }),
    );
  };

  const formik = useFormik({
    initialValues: {
      pharmacyId: pID ?? pharmacyId,
      pharmacies,
      pharmacySelected: pharmacyEmail || pharmacySelected,
    },
    onSubmit: handleSubmit,
  });

  const { data, totalCount } = incompatibleMatchesList;
  const { pharmacyId: formikPharmacyId, pharmacies: formikPharmacies } = formik.values;

  const handleReset = () => {
    dispatch(adminResetValue(resetValues));
  };

  const resetFields = () => {
    formik.setFieldValue('pharmacyId', '');
    formik.setFieldValue('pharmacies', '');
    formik.setFieldValue('pharmacySelected', '');
    handleReset();
  };

  const handlePagination = (_: unknown, selectedPage: number) => {
    const curPage = selectedPage + 1;

    setPage(curPage);
    dispatch(
      adminGetIncompatibleMatches({
        pharmacyId: formikPharmacyId,

        paging: {
          page: curPage,
          sortBy: orderBy,
          desc: isDesc,
        },
      }),
    );
  };

  useEffect(() => {
    debouncedInput(formikPharmacies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikPharmacies]);

  const goToUpdate = () => {
    const config = {
      state: { pharmacyId: formikPharmacyId, pharmacyEmail },
    };

    if (isPharmacyList) {
      navigate(Constants.paths.admin.pharmacyListIncompatibleUpdate, { ...config });
    } else {
      navigate(Constants.paths.admin.updateIncompatibleMatch, { ...config });
    }
  };

  const canUpdate = !!formikPharmacyId && canViewUpdateList;

  const table: IDynamicTableObject = {
    title: strings.incompatibleMatchPharmacist,
    headerBar: canCreateNew(canUpdate, {
      Component: <CreateNew label={strings.updateList} onClick={goToUpdate} />,
      key: '1',
    }),
    headers: incompatibleMatchHeaders(),
    rows: incompatibleMatchRows(data, goToUserProfile),
  };

  const noData = data.length === 0;
  const afterSearchNoData = totalCount === 0 && noData;
  const emptyMsg = afterSearchNoData
    ? strings.noIncompatibleMatches
    : strings.emptyIncompatiblePrompt;

  useEffect(() => {
    if (pID) {
      formik.handleSubmit();
    }

    return () => {
      if (isPharmacyList) {
        handleReset();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pID]);

  useEffect(() => {
    if (orderBy) {
      setPage(1);
      dispatch(
        adminGetIncompatibleMatches({
          pharmacyId: formikPharmacyId,
          paging: {
            page: 1,
            sortBy: orderBy,
            desc: isDesc,
            ...formik.values,
          },
        }),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order]);

  return (
    <Box>
      <DynamicTable
        table={table}
        totalCount={totalCount}
        page={page}
        order={order}
        orderBy={orderBy}
        handleSort={handleSorting}
        handlePagination={handlePagination}
        emptyContainerComponent={
          noData ? <EmptyIncompatibleMatches message={emptyMsg} /> : null
        }
        customHeader={(
          <IncompatibleMatchListHeader
            handleReset={resetFields}
            formik={formik}
            pID={pID}
          />
        )}
        loadSuccess={success}
      />
    </Box>
  );
};

export default IncompatibleMatches;
