import React, { useEffect } from 'react';

import { FormikValues } from 'formik';

import {
  adminActions,
  adminResetValue,
  getPharmacistDetailsSearch,
  resetStatus,
  successOrSelector,
} from '@pharmaplan/common';

import usePharmacistListTabs from '../../../hooks/PharmacistList/usePharmacistListTabs';
import { useComponentDidUpdate } from '../../../hooks/useComponentDidUpdate';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import useUserNotFound from '../../../hooks/useUserNotFound';
import useFormik from '../../../hooks/useFormik/useFormik';
import useFormikUtils from '../../../hooks/useFormik/useFormikUtils';
import useTableSort from '../../../hooks/useTableSort';
import useSelectedTabs from '../../../hooks/Admin/useSelectedTabs';
import { PharmacistListTabTypes } from '../../../helpers/Constants';
import strings from '../../../localization';

import DynamicTable from '../../DynamicTable';
import { IDynamicTableObject } from '../../DynamicTable/types';

import { showSuccess } from '../Profile/Pharmacist/PharmacistViewProfile/helper';
import PharmacistListHeader from './PharmacistListHeader';

const detailsKey = 'pharmacistDetailsSearch';
const { activatePharmacist, showPharmacist } = adminActions;
const successActions = [activatePharmacist, showPharmacist];

export interface IPharmacistListActionsParams {
  pharmacistId: string;
  pharmacistName: string;
}

const initialValues = { userText: '', capacityIds: [], softwareIds: [] };
const { detailsSearch } = PharmacistListTabTypes;

const PharmacistList = () => {
  const dispatch = useAppDispatch();
  const { isDesc, orderBy, handleSorting, resetSorting, order } = useTableSort();

  const success = useAppSelector((state) =>
    successOrSelector(successActions, state));

  const { urlSelectedTab, page, setPage } = useFormikUtils(
    Object.keys(PharmacistListTabTypes),
    PharmacistListTabTypes.activePharmacists,
  );

  const { selectedTabs, handleTabClick } = useSelectedTabs<PharmacistListTabTypes>(
      urlSelectedTab as PharmacistListTabTypes,
  );

  const { headers, rows, api, data, loadSuccess } = usePharmacistListTabs(selectedTabs);
  const {
    totalCount,
    data: { length },
  } = data ?? {};

  const { showUserNotFound } = useUserNotFound(
    loadSuccess,
    !!length,
    strings.emptyIncompatiblePrompt,
  );

  const table: IDynamicTableObject = {
    headerBar: [],
    headers,
    rows,
  };

  const handleApi = (
    values: any,
    inPage: number,
    sortBy?: string,
    desc?: boolean,
  ) => {
    const { softwareIds, capacityIds, userText } = values;

    if (selectedTabs === detailsSearch) {
      dispatch(
        getPharmacistDetailsSearch({
          softwareIds,
          capacityIds,
          paging: { page: inPage },
        }),
      );
    } else {
      dispatch(
        api({
          userText: userText?.trim(),
          page: inPage,
          sortBy: sortBy ?? '',
          desc,
        }),
      );
    }
  };

  const handleSubmit = (values: FormikValues, inPage = 1) => {
    setPage(inPage);
    handleApi(values, inPage, orderBy, isDesc);
  };

  const { formik, clearURL } = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    page,
    selectedTab: selectedTabs,
  });

  const { values } = formik ?? {};

  const handleReset = () => {
    clearURL();
    resetSorting();
    formik.resetForm();
    setPage(1);
    handleApi(initialValues, 1);
  };

  const defaultPageLoad = () => {
    formik.resetForm();
    setPage(1);
    if (selectedTabs !== detailsSearch) {
      handleApi(initialValues, 1);
    } else {
      dispatch(adminResetValue([detailsKey]));
      dispatch(resetStatus([adminActions.getAdminPharmacistDetailsSearch]));
    }
  };

  useComponentDidUpdate(() => {
    defaultPageLoad();
    clearURL();
    resetSorting();
  }, [selectedTabs]);

  useEffect(() => {
    if (success) {
      showSuccess(dispatch, strings.actionCompletedSuccessfully);
      handleApi(values, 1);
      dispatch(resetStatus(successActions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (orderBy) {
      setPage(1);
      dispatch(
        api({
          page: 1,
          sortBy: orderBy,
          desc: isDesc,
          ...values,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order]);

  const handlePagination = (_: unknown, selectedPage: number) => {
    const goToPage = selectedPage + 1;
    setPage(goToPage);
    handleApi(values, goToPage, orderBy, isDesc);
  };

  return (
    <DynamicTable
      table={table}
      loadSuccess={loadSuccess}
      showHeader={false}
      page={page}
      totalCount={totalCount}
      order={order}
      orderBy={orderBy}
      handleSort={handleSorting}
      handlePagination={handlePagination}
      emptyContainerComponent={showUserNotFound()}
      customHeader={(
        <PharmacistListHeader
          formik={formik}
          selectedTab={selectedTabs}
          handleTabClick={handleTabClick}
          handleReset={handleReset}
        />
      )}
    />
  );
};

export default PharmacistList;
