import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { Box } from '@mui/system';

import { advertisementFilters, PublishTypes } from '@pharmaplan/common';
import CustomCheckbox from '../../../../common/CustomCheckbox';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import strings from '../../../../../localization';
import CustomDatePicker from '../../../../common/CustomDatePicker';

import styles from '../styles';
import { serverToday } from '../../../../../helpers/Functions';
import FormikError from '../../../../common/FormikError';

interface IAdvertisingPublish {
  formik: FormikProps<any>;
}

const AdvertisingPublish: FC<IAdvertisingPublish> = ({ formik }) => {
  const filters = useAppSelector(advertisementFilters);
  const { publishTypes } = filters ?? {};

  const isPublishTemporarily = formik.values.publishType === PublishTypes.PublishTemporarily;
  const handlePublishChange = (key: PublishTypes) =>
    () => {
      if (formik.values.publishType === key) {
        formik.setFieldValue('publishType', null);
        return;
      }
      formik.setFieldValue('publishType', key);
    };

  return (
    <>
      <Box sx={styles.publishContainer}>
        {publishTypes.map((item) => {
          const { key, value } = item;
          return (
            <CustomCheckbox
              key={key}
              name={`${key}`}
              label={value}
              id={`${key}`}
              value={formik.values.publishType === key}
              handleChange={handlePublishChange(key)}
            />
          );
        })}
        {!!formik.errors.publishType && !!formik.touched.publishType && (
          <Typography>{strings.pleaseSelectOne}</Typography>
        )}
      </Box>
      {isPublishTemporarily && (
        <Box sx={styles.datePickerContainer}>
          <CustomDatePicker
            error=""
            minDate={serverToday()}
            name="expiry"
            label={strings.expiryDate}
            formik={formik}
            readOnly
          />
          <FormikError formik={formik} name="expiry" />
        </Box>
      )}
    </>
  );
};
export default AdvertisingPublish;
